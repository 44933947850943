import { configureStore } from '@reduxjs/toolkit'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { apiMiddleware, apiReducer } from '@sevenrooms/core/api'
import { Root } from '@sevenrooms/core/ui-kit/layout'
import { Multifactor, type MultifactorProps } from './Multifactor'
import type { SnakeCaseKeys } from 'snakecase-keys'

interface HasGlobalInit {
  globalInit: {
    mfa: SnakeCaseKeys<MultifactorProps>
  }
}

export const store = configureStore({
  reducer: apiReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiMiddleware),
})

const {
  globalInit: { mfa },
} = window as unknown as HasGlobalInit

render(
  <Provider store={store}>
    <Root theme="vx">
      <Multifactor mfaMethod={mfa.mfa_method} totpToken={mfa.totp_token} email={mfa.email} rurl={mfa.rurl ?? undefined} />
    </Root>
  </Provider>,
  document.getElementById('mfa-render')
)
