import { useCallback, useState } from 'react'
import { useResendChallengeMutation, useVerifyChallengeMutation } from '@sevenrooms/core/api'
import { isMultifactorVerifyError, type MultifactorVerifyError, type MultifactorResponse } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, ErrorMessage, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, notify, PreviewImage, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import MfaImg from '../images/mfa_icon.svg'
import { LoginLocales } from '../login.locales'

export interface MultifactorProps extends MultifactorResponse {
  rurl?: string
}

export function Multifactor({ email, totpToken: token, rurl }: MultifactorProps) {
  const { formatMessage } = useLocales()
  const [otp, setOtp] = useState('')
  const [totpToken, setTotpToken] = useState(token)
  const [error, setError] = useState<MultifactorVerifyError | undefined>()
  const [resendChallenge, { isLoading: isSending }] = useResendChallengeMutation()
  const [verifyChallenge, { isLoading: isVerifying, isSuccess }] = useVerifyChallengeMutation()

  const handleResendChallenge = useCallback(async () => {
    try {
      const response = await resendChallenge({ email }).unwrap()
      setTotpToken(response.totpToken)
      notify({
        content: formatMessage(LoginLocales.multifactorResendSuccess),
        type: 'success',
      })
    } catch {
      notify({
        content: formatMessage(LoginLocales.multifactorResendFailure),
        type: 'error',
      })
    }
  }, [email, formatMessage, resendChallenge, setTotpToken])

  const submit = useCallback(async () => {
    try {
      await verifyChallenge({ email, otp, totpToken, rurl }).unwrap()
      setError(undefined)
    } catch (e) {
      if (e instanceof Error && isMultifactorVerifyError(e.message)) {
        setError(e.message)
      } else {
        throw e
      }
    }
  }, [email, otp, totpToken, rurl, verifyChallenge, setError])

  let errorMessage
  if (error === 'PASSCODE_EXPIRED') {
    errorMessage = formatMessage(LoginLocales.multifactorExpired)
  } else if (error === 'PASSCODE_INVALID') {
    errorMessage = formatMessage(LoginLocales.multifactorInvalid)
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        submit()
      }}
    >
      <VStack justifyContent="center" spacing="lm" width="350px">
        <PreviewImage alt={formatMessage(LoginLocales.multifactorHeader)} src={MfaImg} maxHeight="100px" />
        <VStack spacing="m">
          <Text textStyle="h1">{formatMessage(LoginLocales.multifactorHeader)}</Text>
          <Text>{formatMessage(LoginLocales.multifactorDescription)}</Text>
          <Text>
            {formatMessage(LoginLocales.multifactorPrompt, {
              email,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: the type ParseMessage needs to be updated
              strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
            })}
          </Text>
          <VStack spacing="xs">
            <HStack spacing="m" alignItems="end">
              <Box width="250px">
                <Label primary={formatMessage(LoginLocales.multifactorLabel)}>
                  <Input value={otp} onChange={e => setOtp(e.target.value)} invalid={!!error} />
                </Label>
              </Box>
              <Box pb="sm">
                <Anchor textStyle="body1" disabled={isSending} onClick={handleResendChallenge}>
                  {formatMessage(LoginLocales.multifactorResend)}
                </Anchor>
              </Box>
            </HStack>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </VStack>
          <Button
            data-test="verify-button"
            disabled={isVerifying || isSuccess || Number.isNaN(Number(otp)) || otp.length !== 6}
            type="submit"
          >
            {formatMessage(LoginLocales.multifactorVerify)}
          </Button>
        </VStack>
        <Anchor textStyle="body1" href="/login" target="_self">
          {formatMessage(LoginLocales.multifactorBackToLogin)}
        </Anchor>
      </VStack>
    </form>
  )
}
