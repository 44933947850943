import { defineMessages } from '@sevenrooms/core/locales'

export const LoginLocales = defineMessages({
  multifactorHeader: {
    id: 'login.multifactorHeader',
    defaultMessage: 'Multifactor Authentication',
  },
  multifactorDescription: {
    id: 'login.multifactorDescription',
    defaultMessage: 'To make sure your account is secure, we have to verify your identity.',
  },
  multifactorPrompt: {
    id: 'login.multifactorPrompt',
    defaultMessage: 'Enter the verification code we emailed to <strong>{email}</strong>',
  },
  multifactorLabel: {
    id: 'login.multifactorLabel',
    defaultMessage: 'Verification Code',
  },
  multifactorResend: {
    id: 'login.multifactorResend',
    defaultMessage: 'Resend Code',
  },
  multifactorVerify: {
    id: 'login.multifactorVerify',
    defaultMessage: 'Verify',
  },
  multifactorBackToLogin: {
    id: 'login.multifactorBackToLogin',
    defaultMessage: 'Back to Login',
  },
  multifactorInvalid: {
    id: 'login.multifactorInvalid',
    defaultMessage: 'The code you entered is incorrect. Try again.',
  },
  multifactorExpired: {
    id: 'login.multifactorExpired',
    defaultMessage: 'The code you entered is expired. Try again.',
  },
  multifactorResendSuccess: {
    id: 'login.multifactorResendSuccess',
    defaultMessage: 'Code sent',
  },
  multifactorResendFailure: {
    id: 'login.multifactorResendFailure',
    defaultMessage: 'Failed to send code',
  },
} as const)
